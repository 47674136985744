import React, { useState, useEffect } from 'react';
import { HiOutlineChevronDown } from 'react-icons/hi';
import { FaPhoneAlt, FaFacebookF, FaTwitter, FaLinkedinIn, FaYoutube } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import './style.css';
import Drawers from './Drawer';
import { Tabs } from 'antd';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchDestinations } from '../store/asyncaction/destAction';
import { fetchPackages } from '../store/asyncaction/packageAction';
import logo from '../../image/logo.png'

const { TabPane } = Tabs;

const Sheader = () => {

    const [navbar, setNavbar] = useState(false);


    const { destinations } = useSelector(state => state.destReducer);
    const { packages } = useSelector(state => state.packageReducer);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchDestinations());
        dispatch(fetchPackages());
    }, [dispatch]);


    const changeBackground = () => {
        if (window.scrollY >= 80) {
            setNavbar(true);
        }
        else {
            setNavbar(false);
        }
    }
    window.addEventListener('scroll', changeBackground)

    return (
        <div className='navs'>
            <div className="first-header">
                <div className="header-contact">
                    <div className="header-mp">
                        <div className="header-mail d-flex">
                            <FaPhoneAlt className='icon mt-1' />
                            <p className='mt-1'>+977-984-1071644</p>
                        </div>
                        <div className="header-phone d-flex mt-1 ps-md-4">
                            <MdEmail className='icon mt-1' />
                            <p>info@helloworld.com.np</p>
                        </div>
                    </div>
                    <div className="header-social-icon mt-1">
                        <a href="https://www.facebook.com/profile.php?id=100075874434194" target="__blank"><FaFacebookF className='icon' /></a>
                        <FaLinkedinIn className='icon' />
                        <FaTwitter className='icon' />
                        <FaYoutube className='icon' />
                    </div>
                </div>
            </div>
            <hr id='hr' />
            <div className="headers">
                <div className={navbar ? 'nav-bars-active' : 'navbars'}>
                    <nav className='navbar' style={{ marging: '0px', padding: '0px' }}>
                        <Drawers />
                        <div className="nav-logo">
                            <Link to='/'>
                                <img src={logo} height={80} width={190} alt="" />
                            </Link>
                        </div>
                        <div className="nav-center-content">
                            <ul className="d-flex">
                                <li className="nav-item">
                                    <Link to='/' className="nav-link active" aria-current="page">
                                        Home
                                    </Link>
                                </li>
                                <li className="nav-item dropdown dropdown-mega position-static">
                                    <a className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown"
                                        data-bs-auto-close="outside">Destination <HiOutlineChevronDown /></a>
                                    <div className="dropdown-menu mcw shadow">
                                        <Tabs defaultActiveKey="1">
                                            <TabPane tab="Inbound" key="1">
                                                <div className="mega-content px-4">
                                                    <div className="container-fluid mega-flex">
                                                        {destinations.map((d, i) => {
                                                            if (d.category === '6225c55eefcd1ca7491cbf0a') {
                                                                return <div className="pt-4 pb-4" key={i}>
                                                                    <div className="mega-card" onClick={() => window.location.reload(`/details/${d.slug}`)}>
                                                                        <Link to={`/details/${d.slug}`}>
                                                                            <div className="place-card">
                                                                                <img src={`/images/${d.image}`} alt="place__image" />
                                                                                <h4 className='text-center title-limit pt-3' >{d.title}</h4>
                                                                            </div>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            }
                                                            else {
                                                                return <div key={i}></div>
                                                            }
                                                        }
                                                        )}
                                                    </div>
                                                </div>
                                            </TabPane>
                                            <TabPane tab="Outbound" key="2">
                                                <div className="mega-content px-4">
                                                    <div className="container-fluid mega-flex">
                                                        {destinations.map((d, i) => {
                                                            if (d.category === '6225c568efcd1ca7491cbf0e') {
                                                                return <div className="pt-4 pb-4" key={i}>
                                                                    <div className="mega-card" onClick={() => window.location.reload(`/details/${d.slug}`)}>
                                                                        <Link to={`/details/${d.slug}`}>
                                                                            <div className="place-card">
                                                                                <img src={`/images/${d.image}`} alt="place__image" />
                                                                                <h4 className='text-center title-limit pt-3' >{d.title}</h4>
                                                                            </div>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            }
                                                            else {
                                                                return <div key={i}></div>
                                                            }
                                                        }
                                                        )}
                                                    </div>
                                                </div>
                                            </TabPane>
                                        </Tabs>
                                    </div>
                                </li>
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Packages <HiOutlineChevronDown />
                                    </a>
                                    <ul className="dropdown-menu mt-3" aria-labelledby="navbarDropdown">
                                        {packages.map((p, i) => <li key={i} onClick={() => window.location.reload(`/package-details/${p.slug}`)} >
                                            <Link to={`/package-details/${p.slug}`} className="dropdown-item">{p.title}</Link></li>
                                        )}
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <Link to='/about-us' className="nav-link" aria-current="page">
                                        About Us
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to='/all-blogs' className="nav-link" aria-current="page">
                                        Blog
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to='/contact-us' className="nav-link" aria-current="page">
                                        Contact Us
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    )
}

export default Sheader