import {
    SET_LOADER,
    CLOSE_LOADER,
    REDIRECT_TRUE,
    REDIRECT_FALSE,
    SET_MESSAGE,
    REMOVE_MESSAGE,
    SET_CONTACTS
}from '../types/Types'

const initState = { 
    loading: false,
    redirect:false,
    message:'',
    contacts:[]
}

export const ContactReducer = (state=initState,action)=>{
    const {type,payload} =action;
    if(type === SET_LOADER){
        return{...state,loading:true}
    }
   else if(type === CLOSE_LOADER){
        return{...state,loading:false}
    }
   else if(type === REDIRECT_TRUE){
        return{...state,redirect:true}
    }
    else if(type === REDIRECT_FALSE){
        return{...state,redirect:false}
    }
    else if(type === SET_MESSAGE){
        return {...state,message:action.payload}
    }
    else if(type === REMOVE_MESSAGE){
        return {...state,message:''}
    }
    if(type === SET_CONTACTS){
        return {...state,
            contacts: payload.response,}
    }
    else{
        return state;
    }
}
