import React from 'react';
import FadeLoader from "react-spinners/FadeLoader";
import { useParams } from 'react-router-dom';

const Loading = () => {

    const { loading } = useParams();

    return <center>
        <FadeLoader color='#060894' loading={loading}
            height={15} width={5} radius={2} margin={2} className="pt-5" />

    </center>
};

export default Loading;
