import {
    SET_LOADER,
    CLOSE_LOADER,
    CREATE_ERRORS,
    REDIRECT_TRUE,
    REDIRECT_FALSE,
    SET_MESSAGE,
    REMOVE_MESSAGE,
    REMOVE_ERRORS,
    SET_CATEGORIES,
    SET_CATEGORY,
    CATEGORY_REQUEST,
    CATEGORY_RESET,
    SET_UPDATE_ERRORS,
    RESET_UPDATE_ERRORS,
    SET_PACKAGES,
    SET_PACKAGE,
    PACKAGE_REQUEST,
    PACKAGE_RESET,
    UPDATE_IMAGE_ERRORS,
    RESET_UPDATE_IMAGE_ERRORS,
    SET_DETAILS
} from '../types/Types';

const initState = {
    loading: false,
    createErrors: [],
    redirect: false,
    message: '',
    pcategories: [],
    packages:[],
    pcategoryStatus: false,
    packageStatus:false,
    pcategory: {},
    packg:{},
    details:{},
    editErrors: [],
    updateImageErrors: []
}

export const packageReducer = (state = initState, action) => {
    const { type, payload } = action;
    if (type === SET_LOADER) {
        return { ...state, loading: true }
    }
    else if (type === CLOSE_LOADER) {
        return { ...state, loading: false }
    }
    else if (action.type === CREATE_ERRORS) {
        // console.log(action.payload.errors)
        return {
            ...state,
            createErrors: action.payload.errors,
        }
    } else if (type === REDIRECT_TRUE) {
        return { ...state, redirect: true }
    }
    else if (type === REDIRECT_FALSE) {
        return { ...state, redirect: false }
    }
    else if (type === SET_MESSAGE) {
        return { ...state, message: action.payload }
    }
    else if (type === REMOVE_MESSAGE) {
        return { ...state, message: '' }
    }
    else if (type === REMOVE_ERRORS) {
        return { ...state, createErrors: [] }
    }
    // GET all package CATEGORY 

    if (type === SET_CATEGORIES) {
        return {
            ...state,
            pcategories: payload.response
        }
    }
    // get single package category
    if (type === SET_CATEGORY) {
        return { ...state, pcategory: payload };
    } else if (type === CATEGORY_REQUEST) {
        return { ...state, pcategoryStatus: true };
    } else if (type === CATEGORY_RESET) {
        return { ...state, pcategoryStatus: false }
    }

    // update for all
    if (type === SET_UPDATE_ERRORS) {
        return { ...state, editErrors: payload };
    } else if (type === RESET_UPDATE_ERRORS) {
        return {
            ...state, editErrors: [],
        }
    }

     // update image

     if (type === UPDATE_IMAGE_ERRORS) {
        return {
            ...state,
            updateImageErrors: payload,
        };
    } else if (type === RESET_UPDATE_IMAGE_ERRORS) {
        return {
            ...state,
            updateImageErrors: [],
        };
    }

    // all packages

    if (type === SET_PACKAGES) {
        return {
            ...state,
            packages: payload.response
        }
    }
    

    // get single package
    if (type === SET_PACKAGE) {
        return { ...state, packg: payload };
    } else if (type === PACKAGE_REQUEST) {
        return { ...state, packageStatus: true };
    } else if (type === PACKAGE_RESET) {
        return { ...state, packageStatus: false }
    }

     // package details using slug
     else if (type === SET_DETAILS) {
        return { ...state, details: payload }
    }

    else {
        return state;
    }
}

