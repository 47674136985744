import React, { useState, useEffect } from 'react';
import { Drawer } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { Menu } from 'antd';
import './style.css'
import { Link } from 'react-router-dom';
import { fetchDestinations } from '../store/asyncaction/destAction';
import { fetchPackages } from '../store/asyncaction/packageAction';

const { SubMenu } = Menu;

const Drawers = () => {

    const { destinations } = useSelector(state => state.destReducer);
    const { packages, } = useSelector(state => state.packageReducer);

    const [visible, setVisible] = useState(false);
    const showDrawer = () => {
        setVisible(true);
    };
    const onClose = () => {
        setVisible(false);
    };

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchDestinations());
        dispatch(fetchPackages());
    }, [dispatch]);

    return (
        <div className='drawers'>
            <MenuOutlined className='menuicon'
                onClick={showDrawer} />
            <Drawer title="Hello World Tour" placement="left" width="270px"
                onClose={onClose} visible={visible}>
                <Menu
                    style={{ width: 226 }}
                    mode="inline"
                >
                    <Menu.Item key="1">
                        <Link to='/' onClick={onClose}>Home</Link>
                    </Menu.Item>
                    <SubMenu key="sub2" id='sub-menu' title="Destination">
                        {destinations.map((d) => (
                            <Menu.Item key={d._id}>
                                <Link to={`/details/${d.slug}`} onClick={onClose}>{d.title}</Link>
                            </Menu.Item>
                        ))}
                    </SubMenu>
                    <SubMenu key="sub3" id='sub-menu' title="Package">
                        {packages.map((p) => (
                            <Menu.Item key={p._id}>
                                <Link to={`/package-details/${p.slug}`} onClick={onClose}>{p.title}</Link>
                            </Menu.Item>
                        ))}

                    </SubMenu>
                    <Menu.Item key="4">
                        <Link to='/about-us' onClick={onClose}>About Us</Link>
                    </Menu.Item>
                    <Menu.Item key="7">
                        <Link to='/blog' onClick={onClose}>Blog</Link>
                    </Menu.Item>
                    <Menu.Item key="9">
                        <Link to='/contact-us' onClick={onClose}>Contact Us</Link>
                    </Menu.Item>
                </Menu>
            </Drawer>
        </div>
    )
}

export default Drawers