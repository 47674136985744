import axios from 'axios';
import {
    SET_LOADER,
    CLOSE_LOADER,
    CREATE_ERRORS,
    REDIRECT_TRUE,
    SET_MESSAGE,
    REMOVE_ERRORS,
    SET_CATEGORIES,
    SET_CATEGORY,
    CATEGORY_REQUEST,
    SET_UPDATE_ERRORS,
    SET_DESTINATIONS,
    SET_DESTINATION,
    DESTINATION_REQUEST,
    UPDATE_IMAGE_ERRORS,
    SET_DETAILS
} from '../types/Types'

// category

export const dcategoryCreate = (postData) => {
    return async (dispatch, getState) => {
        const {
            authReducer: { token },
        } = getState()
        dispatch({ type: SET_LOADER })
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
            const { data: { msg } } = await axios.post('/dcategory', postData, config);
            dispatch({ type: CLOSE_LOADER });
            dispatch({ type: REMOVE_ERRORS })
            dispatch({ type: REDIRECT_TRUE });
            dispatch({ type: SET_MESSAGE, payload: msg })
        } catch (error) {
            console.log(error.response)
            const errors = error.response.data;
            dispatch({ type: CLOSE_LOADER })
            dispatch({ type: CREATE_ERRORS, payload: errors })
        }
    }
}

export const fetchdCategories = () => {
    return async (dispatch) => {
        dispatch({ type: SET_LOADER });
        try {
            const { data: { response } } = await axios.get('/dcategories');
            dispatch({ type: CLOSE_LOADER });
            dispatch({ type: SET_CATEGORIES, payload: { response } })
            // console.log(response)
        } catch (error) {
            dispatch({ type: CLOSE_LOADER });
        }
    }
}

export const fetchdCategory = (id) => {
    return async (dispatch) => {
        dispatch({ type: SET_LOADER });
        try {
            const {
                data: { category } } = await axios.get(`/dcategory/${id}`);
            dispatch({ type: CLOSE_LOADER });
            dispatch({ type: SET_CATEGORY, payload: category });
            dispatch({ type: CATEGORY_REQUEST });
            // console.log(category)
        } catch (error) {
            dispatch({ type: CLOSE_LOADER });
            console.log(error.message);
        }
    };
};

export const  updatedCategory = (editData)=>{
    return async(dispatch,getState)=>{
        const {
			authReducer: { token },
		} = getState();
		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};
        dispatch({ type: SET_LOADER });
		try {
			const {
				data
			} = await axios.post('/dcategoryupdate', editData,config);
			dispatch({ type: CLOSE_LOADER });
            dispatch({type:REDIRECT_TRUE})
            dispatch({type: SET_MESSAGE, payload: data.msg})
		} catch (error) {
            const {response:{
                data:{errors},
            }}=error;
			dispatch({ type: CLOSE_LOADER });
            dispatch({type: SET_UPDATE_ERRORS,payload: errors});
			console.log(error.response);
		}
    }
}


// destination

export const destCreate = (postData) => {
    return async (dispatch, getState) => {
        const {
            authReducer: { token },
        } = getState()
        dispatch({ type: SET_LOADER })
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
            const { data: { msg } } = await axios.post('/destination', postData, config);
            dispatch({ type: CLOSE_LOADER });
            dispatch({ type: REMOVE_ERRORS })
            dispatch({ type: REDIRECT_TRUE });
            dispatch({ type: SET_MESSAGE, payload: msg })
        } catch (error) {
              const errors = error.response.data;
            dispatch({ type: CLOSE_LOADER })
            dispatch({ type: CREATE_ERRORS,payload:errors })
        }
    }
}

export const fetchDestinations = () => {
    return async (dispatch) => {
        dispatch({ type: SET_LOADER });
        try {
            const { data: { response } } = await axios.get('/destinations');
            dispatch({ type: CLOSE_LOADER });
            dispatch({ type: SET_DESTINATIONS, payload: { response } })
            // console.log(response)
        } catch (error) {
            dispatch({ type: CLOSE_LOADER });
        }
    }
}


export const fetchDest = (id) => {
    return async (dispatch) => {
        dispatch({ type: SET_LOADER });
        try {
            const {
                data: { destination } } = await axios.get(`/destination/${id}`);
            dispatch({ type: CLOSE_LOADER });
            dispatch({ type: SET_DESTINATION, payload: destination });
            dispatch({ type: DESTINATION_REQUEST });
            // console.log(destination)
        } catch (error) {
            dispatch({ type: CLOSE_LOADER });
            console.log(error.message);
        }
    };
};




export const updateDest = (editData)=>{
    return async(dispatch,getState)=>{
        const {
			authReducer: { token },
		} = getState();
		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};
        dispatch({ type: SET_LOADER });
		try {
			const {
				data
			} = await axios.post('/destupdate', editData,config);
			dispatch({ type: CLOSE_LOADER });
            dispatch({type:REDIRECT_TRUE})
            dispatch({type: SET_MESSAGE, payload: data.msg})
		} catch (error) {
            const {response:{
                data:{errors},
            }}=error;
			dispatch({ type: CLOSE_LOADER });
            dispatch({type: SET_UPDATE_ERRORS,payload: errors});
			console.log(error.response);
		}
    }
}

export const updateDestImage = (updateData)=>{
    return async(dispatch,getState)=>{
        const {
			authReducer: { token },
		} = getState();
		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};
        dispatch({ type: SET_LOADER });
        try {
			const {
				data: { msg },
			} = await axios.post('/updatedImage', updateData, config);
			dispatch({ type: CLOSE_LOADER });
			dispatch({ type: REDIRECT_TRUE });
			dispatch({ type: SET_MESSAGE, payload: msg });
		} catch (error) {
			const {
				response: {
					data: { errors },
				},
			} = error;
			dispatch({ type: CLOSE_LOADER });
			dispatch({ type: UPDATE_IMAGE_ERRORS, payload: errors });
		}
    }
}


export const destDetails = (slug) =>{
	return async (dispatch) =>{
		dispatch({type:SET_LOADER});
		try {
			const {data: {destination}} = await axios.get(`/destinationdetails/${slug}`);
			dispatch({type: CLOSE_LOADER});
			dispatch({type: SET_DETAILS, payload: destination});
		} catch (error) {
			dispatch({type:CLOSE_LOADER})
			console.log(error)
		}
	}
}
