import {
    SET_LOADER,
    CLOSE_LOADER,
    CREATE_ERRORS,
    REDIRECT_TRUE,
    REDIRECT_FALSE,
    SET_MESSAGE,
    REMOVE_MESSAGE,
    REMOVE_ERRORS,
    SET_CATEGORIES,
    SET_CATEGORY,
    CATEGORY_REQUEST,
    CATEGORY_RESET,
    SET_UPDATE_ERRORS,
    RESET_UPDATE_ERRORS,
    SET_DESTINATIONS,
    SET_DESTINATION,
    DESTINATION_REQUEST,
    DESTINATION_RESET,
    UPDATE_IMAGE_ERRORS,
    RESET_UPDATE_IMAGE_ERRORS,
    SET_DETAILS
} from '../types/Types';

const initState = {
    loading: false,
    createErrors: [],
    redirect: false,
    message: '',
    dcategories: [],
    destinations: [],
    dcategoryStatus: false,
    destStatus: false,
    dcategory: {},
    destination: {},
    details: {},
    editErrors: [],
    updateImageErrors: []
}

export const destReducer = (state = initState, action) => {
    const { type, payload } = action;
    if (type === SET_LOADER) {
        return { ...state, loading: true }
    }
    else if (type === CLOSE_LOADER) {
        return { ...state, loading: false }
    }
    else if (action.type === CREATE_ERRORS) {
        // console.log(action.payload.errors)
        return {
            ...state,
            createErrors: action.payload.errors
        }
    } else if (type === REDIRECT_TRUE) {
        return { ...state, redirect: true }
    }
    else if (type === REDIRECT_FALSE) {
        return { ...state, redirect: false }
    }
    else if (type === SET_MESSAGE) {
        return { ...state, message: action.payload }
    }
    else if (type === REMOVE_MESSAGE) {
        return { ...state, message: '' }
    }
    else if (type === REMOVE_ERRORS) {
        return { ...state, createErrors: [] }
    }
    // GET all package CATEGORY 

    if (type === SET_CATEGORIES) {
        return {
            ...state,
            dcategories: payload.response
        }
    }
    // get single package category
    if (type === SET_CATEGORY) {
        return { ...state, dcategory: payload };
    } else if (type === CATEGORY_REQUEST) {
        return { ...state, dcategoryStatus: true };
    } else if (type === CATEGORY_RESET) {
        return { ...state, dcategoryStatus: false }
    }

    // update for all
    if (type === SET_UPDATE_ERRORS) {
        return { ...state, editErrors: payload };
    } else if (type === RESET_UPDATE_ERRORS) {
        return {
            ...state, editErrors: [],
        }
    }

    // get all destination

    if (type === SET_DESTINATIONS) {
        return {
            ...state,
            destinations: payload.response
        }
    }

    // get single dest

    if (type === SET_DESTINATION) {
        return { ...state, destination: payload };
    } else if (type === DESTINATION_REQUEST) {
        return { ...state, destStatus: true };
    } else if (type === DESTINATION_RESET) {
        return { ...state, destStatus: false }
    }

    // update image

    if (type === UPDATE_IMAGE_ERRORS) {
        return {
            ...state,
            updateImageErrors: payload,
        };
    } else if (type === RESET_UPDATE_IMAGE_ERRORS) {
        return {
            ...state,
            updateImageErrors: [],
        };
    }

    // destination details using slug
    else if (type === SET_DETAILS) {
        return { ...state, details: payload }
    }

    else {
        return state;
    }
}

