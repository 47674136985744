import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Loading from "./components/loading/Loading";
import SearchItem from "./pages/SearchItem";

const UpdatePImage = lazy(() => import('./pages/admin/pages/packages/UpdatePImage'));

const AllHBlogs = lazy(() => import('./pages/AllHBlogs'));
const BlogDetails = lazy(() => import('./pages/details/BlogDetails'));
const NotFound = lazy(() => import('./components/notfound/NotFound'));
const PrivateRoute = lazy(() => import('./components/routes/PrivateRoute'));
const RouteLinks = lazy(() => import('./components/routes/RouteLink'));
const About = lazy(() => import('./pages/About'));
const Dashboard = lazy(() => import('./pages/admin/Dashboard'));
const AllBlogs = lazy(() => import('./pages/admin/pages/blog/AllBlogs'));
const CreateBlog = lazy(() => import('./pages/admin/pages/blog/CreateBlog'));
const UpdateBlog = lazy(() => import('./pages/admin/pages/blog/UpdateBlog'));
const UpdateBlogImage = lazy(() => import('./pages/admin/pages/blog/UpdateBlogImage'))

const AllContact = lazy(() => import('./pages/admin/pages/contact/AllContact'));
const AllDestinations = lazy(() => import('./pages/admin/pages/destination/AllDestinations'));

const CreateDest = lazy(() => import('./pages/admin/pages/destination/CreateDest'));
const AlldCategory = lazy(() => import('./pages/admin/pages/destination/dcategory/AlldCategory'));
const CreatedCategory = lazy(() => import('./pages/admin/pages/destination/dcategory/CreatedCategory'));
const UpdatedCategory = lazy(() => import('./pages/admin/pages/destination/dcategory/UpdatedCategory'))
const UpdateDest = lazy(() => import('./pages/admin/pages/destination/UpdateDest'));
const UpdateDestImage = lazy(() => import('./pages/admin/pages/destination/UpdateDestImg'));
const AllPackages = lazy(() => import('./pages/admin/pages/packages/AllPackages'));
const CreatePackage = lazy(() => import('./pages/admin/pages/packages/CreatePackage'));
const AllpCategories = lazy(() => import('./pages/admin/pages/packages/pcategory/AllpCategories'));
const CreatePcategory = lazy(() => import('./pages/admin/pages/packages/pcategory/CreatePcategory'));
const UpdatepCAtegory = lazy(() => import('./pages/admin/pages/packages/pcategory/UpdatepCAtegory'));
const UpdatePackage = lazy(() => import('./pages/admin/pages/packages/UpdatePackage'));

const AllTeams = lazy(() => import('./pages/admin/pages/team/AllTeams'))

const Createteam = lazy(() => import('./pages/admin/pages/team/Createteam'));
const TeamUpdate = lazy(() => import('./pages/admin/pages/team/TeamUpdate'));
const UpdateTeamImage = lazy(() => import('./pages/admin/pages/team/UpdateTeamimg'));
const AllTesti = lazy(() => import('./pages/admin/pages/testi/AllTesti'));
const CreateTesti = lazy(() => import('./pages/admin/pages/testi/CreateTesti'));
const UpdateTesti = lazy(() => import('./pages/admin/pages/testi/UpdateTesti'));
const UpdateTestiImage = lazy(() => import('./pages/admin/pages/testi/UpdateTestiImage'));
const Login = lazy(() => import('./pages/auth/Login'));
const Contact = lazy(() => import('./pages/Contact'));
const DestDetails = lazy(() => import('./pages/details/DestDetails'));
const PackageDetails = lazy(() => import('./pages/details/PackageDetails'));
const Home = lazy(() => import('./pages/Home'));


const App = () => {
  return (
    <Router>
      <Suspense fallback={
        <div style={{ paddingTop: '240px' }}>
          <Loading />
        </div>
      }>
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/search' component={SearchItem} />
          <Route exact path='/about-us' component={About} />
          <Route exact path='/contact-us' component={Contact} />
          <Route exact path='/details/:slug' component={DestDetails} />
          <Route exact path='/all-blogs' component={AllHBlogs} />
          <Route exact path='/package-details/:slug' component={PackageDetails} />
          <Route exact path='/blog-detail/:slug' component={BlogDetails} />
          <RouteLinks exact path='/admin/login' component={Login} />
          <PrivateRoute exact path='/dashboard' component={Dashboard} />
          <PrivateRoute exact path='/create-pcategory' component={CreatePcategory} />
          <PrivateRoute exact path='/all-pcategory' component={AllpCategories} />
          <PrivateRoute exact path='/update-pcategory/:id' component={UpdatepCAtegory} />

          <PrivateRoute exact path='/create-dcategory' component={CreatedCategory} />
          <PrivateRoute exact path='/all-dcategory' component={AlldCategory} />
          <PrivateRoute exact path='/update-dcategory/:id' component={UpdatedCategory} />

          <PrivateRoute exact path='/create-dest' component={CreateDest} />
          <PrivateRoute exact path='/all-destinations' component={AllDestinations} />
          <PrivateRoute exact path='/update-dest/:id' component={UpdateDest} />
          <PrivateRoute exact path="/updatedImage/:id" component={UpdateDestImage} />

          <PrivateRoute exact path='/create-package' component={CreatePackage} />
          <PrivateRoute exact path='/all-packages' component={AllPackages} />
          <PrivateRoute exact path='/update-package/:id' component={UpdatePackage} />
          <PrivateRoute exact path="/updatepkImage/:slug" component={UpdatePImage} />

          <PrivateRoute exact path='/add-news' component={CreateBlog} />
          <PrivateRoute exact path='/all-blogss' component={AllBlogs} />
          <PrivateRoute exact path='/update-blog/:id' component={UpdateBlog} />
          <PrivateRoute exact path="/updatebImage/:id" component={UpdateBlogImage} />

          <PrivateRoute exact path='/add-tes' component={CreateTesti} />
          <PrivateRoute exact path='/all-tes' component={AllTesti} />
          <PrivateRoute exact path='/update-testi/:id' component={UpdateTesti} />
          <PrivateRoute exact path="/updatettImage/:id" component={UpdateTestiImage} />

          <PrivateRoute exact path='/add-member' component={Createteam} />
          <PrivateRoute exact path='/all-teams' component={AllTeams} />
          <PrivateRoute exact path='/update-team/:id' component={TeamUpdate} />
          <PrivateRoute exact path="/updatemImage/:id" component={UpdateTeamImage} />

          <PrivateRoute exact path="/all-contacts" component={AllContact} />

          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </Router>
  );
}

export default App;
