import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Sheader from '../components/headers/Sheader';
import { FaAngleDoubleRight } from 'react-icons/fa';
import './about.css'
import Footer from '../components/footers/Footer';
import { fetchPackages, fetchpCategories } from '../components/store/asyncaction/packageAction';
import { fetchDestinations } from '../components/store/asyncaction/destAction';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const SearchItem = () => {

    const { packages, pcategories } = useSelector(state => state.packageReducer);
    const { destinations } = useSelector(state => state.destReducer);
    const dispatch = useDispatch();

    const location = useLocation();

    useEffect(() => {
        dispatch(fetchPackages());
        dispatch(fetchpCategories());
        dispatch(fetchDestinations());
    }, [dispatch]);


    return (
        <div className='about'>
            <Sheader />
            <div className="about-section">
                <div className="abouts p-3">
                    <div className="about-head pt-3">
                        <h3>Search Package</h3>
                        <p>Home <FaAngleDoubleRight /> Packages</p>
                    </div>
                </div>
            </div>
            <div className="about-us-content col-md-10 offset-md-1">
                <div className="about-heads pt-5 pb-2">
                    <h3>Packages</h3>
                </div>
                <div className="packages pb-3">
                    <div className="popular-package-card">
                        <div className="psection" >
                            {packages && packages.map((p, i) => (
                                <div key={i}>
                                    {((location.search.slice(1, 25)) === p._id) ? <div>
                                        <div className="psection" key={i}>
                                            <Link to={`/package-details/${p.slug}`}>
                                                <div className="package-card">
                                                    <div className="card-image">
                                                        <img src={`/${p.image}`} alt="dest" />
                                                    </div>
                                                    <div className="card-content">
                                                        <div className="card-category">

                                                            {pcategories.map((c, i) => {
                                                                if (c._id === p.category) {
                                                                    return <p key={i}>{c.title}</p>
                                                                }
                                                                else {
                                                                    return <span key={i}></span>
                                                                }
                                                            })}
                                                        </div>
                                                        <div className="card-location">
                                                            <div className="dflex">
                                                                {destinations.map((d, i) => (
                                                                    <div key={i}>
                                                                        {p.destination.map((dd, i) => {
                                                                            if (d._id === dd) {
                                                                                return <p key={i} className='location'> <FaMapMarkerAlt className='cicon' />{d.title}</p>
                                                                            }
                                                                            else return <span key={i}></span>
                                                                        })}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <p className='duration'>{p.duration}</p>
                                                            <p className='name'>{p.title}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>

                                    </div> : <div></div>}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default SearchItem