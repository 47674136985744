import {createStore,applyMiddleware,combineReducers} from 'redux';
import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension';

import authReducer from './reducers/authReducer';
import {packageReducer} from './reducers/packageReducer';
import {destReducer} from './reducers/destReducer';
import {blogReducer} from './reducers/blogReducer';
import {testiReducer} from './reducers/testiReducer';
import {teamReducer} from './reducers/teamReducer';
import {ContactReducer} from './reducers/ContactReducer'

const rootReducers = combineReducers({
    authReducer,
    packageReducer,
    destReducer,
    blogReducer,
    testiReducer,
    teamReducer,
    ContactReducer, 
})
const middlewares = [thunkMiddleware];
const Store = createStore(rootReducers,composeWithDevTools(applyMiddleware(...middlewares)))
export default Store