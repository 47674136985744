import axios from 'axios';
import {
    SET_LOADER,
    CLOSE_LOADER,
    CREATE_ERRORS,
    REDIRECT_TRUE,
    SET_MESSAGE,
    REMOVE_ERRORS,
    SET_CATEGORIES,
    SET_CATEGORY,
    CATEGORY_REQUEST,
    SET_UPDATE_ERRORS,
    SET_PACKAGES,
    SET_PACKAGE,
    PACKAGE_REQUEST,
    UPDATE_IMAGE_ERRORS,
    SET_DETAILS
} from '../types/Types'

// category

export const pcategoryCreate = (postData) => {
    return async (dispatch, getState) => {
        const {
            authReducer: { token },
        } = getState()
        dispatch({ type: SET_LOADER })
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
            const { data: { msg } } = await axios.post('/pcategory', postData, config);
            dispatch({ type: CLOSE_LOADER });
            dispatch({ type: REMOVE_ERRORS })
            dispatch({ type: REDIRECT_TRUE });
            dispatch({ type: SET_MESSAGE, payload: msg })
        } catch (error) {
            console.log(error.response)
            const errors = error.response.data;
            dispatch({ type: CLOSE_LOADER })
            dispatch({ type: CREATE_ERRORS, payload: errors })
        }
    }
}

export const fetchpCategories = () => {
    return async (dispatch) => {
        dispatch({ type: SET_LOADER });
        try {
            const { data: { response } } = await axios.get('/pcategoriess');
            dispatch({ type: CLOSE_LOADER });
            dispatch({ type: SET_CATEGORIES, payload: { response } })
            // console.log(response)
        } catch (error) {
            dispatch({ type: CLOSE_LOADER });
        }
    }
}

export const fetchpCategory = (id) => {
    return async (dispatch) => {
        dispatch({ type: SET_LOADER });
        try {
            const {
                data: { category } } = await axios.get(`/pcategory/${id}`);
            dispatch({ type: CLOSE_LOADER });
            dispatch({ type: SET_CATEGORY, payload: category });
            dispatch({ type: CATEGORY_REQUEST });
            // console.log(category)
        } catch (error) {
            dispatch({ type: CLOSE_LOADER });
            console.log(error.message);
        }
    };
};

export const  updatepCategory = (editData)=>{
    return async(dispatch,getState)=>{
        const {
			authReducer: { token },
		} = getState();
		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};
        dispatch({ type: SET_LOADER });
		try {
			const {
				data
			} = await axios.post('/pcategoryupdate', editData,config);
			dispatch({ type: CLOSE_LOADER });
            dispatch({type:REDIRECT_TRUE})
            dispatch({type: SET_MESSAGE, payload: data.msg})
		} catch (error) {
            const {response:{
                data:{errors},
            }}=error;
			dispatch({ type: CLOSE_LOADER });
            dispatch({type: SET_UPDATE_ERRORS,payload: errors});
			console.log(error.response);
		}
    }
}


export const packageCreate = (postData) => {
    return async (dispatch, getState) => {
        const {
            authReducer: { token },
        } = getState()
        dispatch({ type: SET_LOADER })
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
            const { data: { msg } } = await axios.post('/package', postData, config);
            dispatch({ type: CLOSE_LOADER });
            dispatch({ type: REMOVE_ERRORS })
            dispatch({ type: REDIRECT_TRUE });
            dispatch({ type: SET_MESSAGE, payload: msg })
        } catch (error) {
            console.log(error.response)
            const errors = error.response.data;
            dispatch({ type: CLOSE_LOADER })
            dispatch({ type: CREATE_ERRORS, payload: errors })
        }
    }
}

export const fetchPackages = () => {
    return async (dispatch) => {
        dispatch({ type: SET_LOADER });
        try {
            const { data: { response } } = await axios.get('/packages');
            dispatch({ type: CLOSE_LOADER });
            dispatch({ type: SET_PACKAGES, payload: { response } })
            // console.log(response)
        } catch (error) {
            dispatch({ type: CLOSE_LOADER });
        }
    }
}

export const fetchPackage = (id) => {
    return async (dispatch) => {
        dispatch({ type: SET_LOADER });
        try {
            const {
                data: { packg } } = await axios.get(`/package/${id}`);
            dispatch({ type: CLOSE_LOADER });
            dispatch({ type: SET_PACKAGE, payload: packg });
            dispatch({ type: PACKAGE_REQUEST });
            // console.log(packg)
        } catch (error) {
            dispatch({ type: CLOSE_LOADER });
            console.log(error.message);
        }
    };
};

export const  updatepPackage= (editData)=>{
    return async(dispatch,getState)=>{
        const {
			authReducer: { token },
		} = getState();
		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};
        dispatch({ type: SET_LOADER });
		try {
			const {
				data
			} = await axios.post('/packageupdate', editData,config);
			dispatch({ type: CLOSE_LOADER });
            dispatch({type:REDIRECT_TRUE})
            dispatch({type: SET_MESSAGE, payload: data.msg})
		} catch (error) {
            const {response:{
                data:{errors},
            }}=error;
			dispatch({ type: CLOSE_LOADER });
            dispatch({type: SET_UPDATE_ERRORS,payload: errors});
			console.log(error.response);
		}
    }
}

export const updatePImage = (slug,updateData)=>{
    return async(dispatch,getState)=>{
        const {
			authReducer: { token },
		} = getState();
		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};
        dispatch({ type: SET_LOADER });
        try {
			const {
				data: { msg },
			} = await axios.put(`/updatepImage/${slug}`, updateData, config);
			dispatch({ type: CLOSE_LOADER });
			dispatch({ type: REDIRECT_TRUE });
			dispatch({ type: SET_MESSAGE, payload: msg });
		} catch (error) {
			const {
				response: {
					data: { errors },
				},
			} = error;
			dispatch({ type: CLOSE_LOADER });
			dispatch({ type: UPDATE_IMAGE_ERRORS, payload: errors });
		}
    }
}


export const packageDetails = (slug) =>{
	return async (dispatch) =>{
		dispatch({type:SET_LOADER});
		try {
			const {data: {packg}} = await axios.get(`/packagedetails/${slug}`);
			dispatch({type: CLOSE_LOADER});
			dispatch({type: SET_DETAILS, payload: packg});
		} catch (error) {
			dispatch({type:CLOSE_LOADER})
			console.log(error)
		}
	}
}
