export const CREATE_ERRORS = 'CREATE_ERRORS';
export const REMOVE_ERRORS = 'REMOVE_ERRORS';
export const SET_LOADER = 'SET_LOADER';
export const CLOSE_LOADER = 'CLOSE_LOADER';
export const REDIRECT_TRUE = 'REDIRECT_TURE';
export const REDIRECT_FALSE = 'REDIRECT_FALSE';
export const SET_MESSAGE = 'SET_MESSAGE';
export const REMOVE_MESSAGE = 'REMOVE_MESSAGE';
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_CATEGORY = 'SET_CATEGORY';
export const CATEGORY_REQUEST = 'CATEGORY_REQUEST';
export const CATEGORY_RESET = 'CATEGORY_RESET';
export const  SET_UPDATE_ERRORS = 'SET_UPDATE_ERRORS';
export const  RESET_UPDATE_ERRORS = 'RESET_UPDATE_ERRORS';
export const   SET_DESTINATIONS = 'SET_DESTINATIONS';
export const   SET_DESTINATION = 'SET_DESTINATION';
export const   DESTINATION_REQUEST = 'DESTINATION_REQUEST';
export const   DESTINATION_RESET = 'DESTINATION_RESET';
export const   UPDATE_IMAGE_ERRORS = 'UPDATE_IMAGE_ERRORS';
export const   RESET_UPDATE_IMAGE_ERRORS = 'RESET_UPDATE_IMAGE_ERRORS';
export const   SET_PACKAGES = 'SET_PACKAGES';
export const   SET_PACKAGE = 'SET_PACKAGE';
export const   PACKAGE_REQUEST = 'PACKAGE_REQUEST';
export const   PACKAGE_RESET = 'PACKAGE_RESET';

export const  SET_BLOGS = ' SET_BLOGS';
export const  SET_BLOG = ' SET_BLOG';
export const  SET_DETAILS = ' SET_DETAILS';
export const  BLOG_RESET = ' BLOG_RESET';
export const  BLOG_REQUEST = ' BLOG_REQUEST';

export const  SET_TESTIS = ' SET_TESTIS';
export const  SET_TESTI = ' SET_TESTI';
export const  TESTI_RESET = ' TESTI_RESET';
export const  TESTI_REQUEST = ' TESTI_REQUEST';

export const  SET_TEAMS = 'SET_TEAMS';
export const  SET_TEAM = 'SET_TEAM';
export const  TEAM_REQUEST = 'TEAM_REQUEST';
export const  TEAM_RESET = 'TEAM_RESET';

export const  SET_CONTACTS = 'SET_CONTACTS';




